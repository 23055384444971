(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("lodash"), require("immutable"), require("react-dom"), require("santa-components"), require("react-dom/server"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "lodash", "immutable", "reactDOM", "santa-components", "reactDOMServer"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("prop-types"), require("lodash"), require("immutable"), require("react-dom"), require("santa-components"), require("react-dom/server")) : factory(root["React"], root["prop-types"], root["_"], root["immutable"], root["ReactDOM"], root["santaComponents"], root["reactDOMServer"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__99__) {
return 